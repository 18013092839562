import { find, orderBy } from 'lodash';
import React from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import {
  ApolloClient,
  ApolloProvider,
  FieldPolicy,
  HttpLink,
  InMemoryCache,
  split,
  StoreObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SIDEKICK_APOLLO_TYPE_POLICIES } from '@assured/sidekick/src/constants';
import { noticeError } from '@assured/telemetry/src/common';
import { captureException } from '@sentry/react';

import { CaseContact } from '../components/CaseView/types';
import config from '../config';
import { useAuth } from '../hooks/useAuth';
import { relayStylePagination } from './pagination';

let previousToken: string;

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body);
  if (!operationName) {
    return fetch(uri, options);
  }

  const params = new URLSearchParams(uri.split('?')[1]);
  params.set('operationName', operationName);
  return fetch(`${uri.split('?')[0]}?${params.toString()}`, options);
};

export const AuthenticatedApolloProvider: React.FC = ({ children }) => {
  const authData = useAuth();

  const getTokenSilently: () => Promise<string | undefined> = async () => {
    if (config.authentication) {
      try {
        const token = await authData.getAccessTokenSilently();
        return `user_${token}`;
      } catch (e: any) {
        // fall through
      }
    }

    const params = new URLSearchParams(window.location.search);
    const key = params.get('token');
    if (key) previousToken = key;
    return key || process.env.REACT_APP_API_KEY || previousToken;
  };

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}`,
        );
        const error = new Error(message);
        error.name = 'ApolloGraphQLError';
        noticeError(error);
        captureException(error);
      });
    if (networkError) {
      noticeError(networkError);
      // eslint-disable-next-line no-console
      console.log(
        `!!!!\n[Network error]: Can not reach the backend server. ${networkError}\n!!!`,
      );
    }
  });

  const uri = (() => {
    if (process.env.REACT_APP_NODE_ENV === 'dev') {
      if (window.location.href.includes('172.17.0.1')) {
        return process.env.REACT_APP_ENDPOINT?.replace(
          'localhost',
          'app-backend',
        );
      }

      if (window.location.href.includes('host.docker.internal')) {
        return process.env.REACT_APP_ENDPOINT?.replace(
          'localhost',
          'host.docker.internal',
        );
      }
    }

    return process.env.REACT_APP_ENDPOINT;
  })();

  const httpLink = new HttpLink({
    uri,
    credentials: 'include',
    fetch: customFetch,
  });

  const subscriptionClient = new SubscriptionClient(
    config.graphqlSubscriptionsEndpoint,
    {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        const token = await getTokenSilently();
        return { authorization: token ? `Bearer ${token}` : '' };
      },
    },
  );
  // TODO: When we upgrade Apollo, we'll also want to upgrade subscriptions-transport-ws to graphql-ws
  const wsLink = new WebSocketLink(subscriptionClient);
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const authLink = setContext(async (_, { headers }) => {
    if (headers?.authorization) {
      return { headers };
    }

    const token = await getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  function makeListFieldPolicy(): FieldPolicy<any> {
    return {
      keyArgs: ['filter', 'filterMode', 'filterUser', 'sortInfo'],
      merge(existing = [], incoming, { args }) {
        if (args?.cursor) {
          return [...existing, ...incoming];
        } else if (incoming.length) {
          return [...incoming, ...existing.slice(incoming.length)];
        } else {
          return [];
        }
      },
    };
  }

  const apolloClient = new ApolloClient({
    link: errorLink.concat(authLink).concat(splitLink),
    typeDefs: './client-schema.graphql',
    cache: new InMemoryCache({
      typePolicies: {
        ...SIDEKICK_APOLLO_TYPE_POLICIES,
        Case: {
          fields: {
            contacts: {
              read(contacts, options) {
                const isSortable = find(
                  options.field?.selectionSet?.selections,
                  { name: { kind: 'Name', value: 'reportState' } },
                );

                if (!isSortable) {
                  return contacts;
                }

                const sortFunc = (c: StoreObject) => {
                  const reportState: CaseContact['reportState'] =
                    options.readField('reportState', c) || {};

                  return [
                    reportState.submittedAt,
                    reportState.lastEngagedAt,
                    reportState.firstContactedAt,
                  ];
                };

                return orderBy(contacts, sortFunc, ['desc']);
              },
            },
          },
        },
        Query: {
          fields: {
            adminClaims: makeListFieldPolicy(),
            adminCases: makeListFieldPolicy(),
          },
        },
        ConversationMessageMacroHydratedGlobalVariable: {
          keyFields: false,
        },
        Conversation: {
          fields: {
            timeline: relayStylePagination(['@connection', ['key']]),
          },
        },
        ConversationMessage: {
          fields: {
            markedAsRead: {
              read(existing) {
                return existing ?? false;
              },
            },
          },
        },
      },
    }),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
